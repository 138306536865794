import React, {useState, useContext} from 'react';

import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../../shared/context/auth-context';
import {  timeFormatterWithText} from '../../shared/helpers/formatters'
import {calculateYesNo, resolveBetStatus, generateWagerStatus } from '../../shared/helpers/game-formatters'
import BetPlayersH2H from './BetPlayersH2H';
import BetPlayersHouse from './BetPlayersHouse';


//shows up on bet detail page
const smallBlack = { fontSize: 12 , color: "#black" };
const small = { fontSize: 15};

const BetHeader = props => {
  const auth = useContext(AuthContext);
  const [betStatus, setBetStatus] = useState();
  const [betStatusColor, setBetStatusColor] = useState();
  const [betaction, setBetAction] = useState();
  const [houseTeam, setHouseTeam] = useState(false);
  const [yesPlayer, setYesPlayer] = useState();
  const [noPlayer, setNoPlayer] = useState();
  const splitText = props.text.split('\n');
  
  if(betStatus===undefined){
    const [results, color] = resolveBetStatus(auth, props.wager )
    setBetStatus( results);
    setBetStatusColor(color);
    setBetAction(generateWagerStatus(props.wager, results, auth.userId).split('\n'));
    setHouseTeam(props.wager.game.house && !props.wager.game.rankingGame);
    const [yes, no] = calculateYesNo(props.wager);
    setYesPlayer(yes);
    setNoPlayer(no);
  }
  
  if(yesPlayer && noPlayer){
    return (
      <React.Fragment>
       <Grid>
         <Typography style={smallBlack}>
           <div style={{float: 'left', marginTop:'10px', paddingLeft: '10px'}}> <b>{props.event.name}</b> 
             <br></br> {timeFormatterWithText("done", props.event.eventCloseDate, "")}</div> 
           </Typography>

         <Typography variant="body1" color="white">
           <div style={{ float: 'right', textAlign: 'right', backgroundColor: `${betStatusColor}` , paddingLeft:'10px', paddingRight:'10px' , paddingTop:'10px', paddingBottom:'10px'}}>
             {betStatus}
           </div>
         </Typography>
         <br></br>
       
       </Grid>

       <CardContent>

       <Typography  color="black" marginTop="5px">
        <div style={{textAlign: 'left', marginLeft: '10px'}}>
          {splitText.map((line, index) => (
            <React.Fragment key={index}>
              <h2>{line}</h2>
            </React.Fragment>
          ))}
        </div>
       </Typography>

       {!props.wager.game.house && (
          <BetPlayersH2H yesPlayer={yesPlayer} noPlayer={noPlayer} />
        )}
        {houseTeam && (
          <BetPlayersHouse wager={props.wager}  canwager={props.canwager} />
        )}

         <Typography color = 'primary' style={small}>
           <div style={{textAlign: 'center', marginLeft: '10px', paddingTop: '15px'}}>{betaction[0]}<br/>{betaction[1]}</div>  
         </Typography> 

       </CardContent>
     
     </React.Fragment>
    );
  }


  
  
};

export default BetHeader;
