import React , {useEffect, useState} from 'react';
import { Link }  from 'react-router-dom'
import dayjs from 'dayjs';

import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Grid } from '@mui/material';

import {commentTruncator} from '../../shared/helpers/formatters'
import Card from '../../shared/components/UIElements/Card';

const ProfileLeaderboard = ({wagers, player}) => {

  const [opponentMap, setOpponentMap] = useState(new Map());
  
  function createStats( suffix, current, arbd, wins, losses, details) {
    const median = 0;
    return { suffix, current, arbd, wins, losses, median, details}; 
  }
  
  useEffect(() => {
    const myUsername = player.username;
    const myId = player.userId;
    let newMap = new Map();

    if(player.friends){
      player.friends.forEach(friend => {
        if(friend.friend===null || friend.friend.username===null){return;}
        
        if(!newMap.has(friend.friend.username)){
          const suffix = friend.status==="pending"?" (P)":"";
          newMap.set(friend.friend.username,  createStats(suffix,0,0,0,0,[]));
        }
      })
    }
    const h2hWagers = wagers.filter(wager => !wager.house);
    h2hWagers.forEach(wager => {
      let myOpponent = wager.opponent.username;
      if(myOpponent===myUsername){
        myOpponent = wager.creator.username;
      }

      let stats = null;
      if(newMap.has(myOpponent)){
        stats = newMap.get(myOpponent);               
      } else {
        stats = createStats('',0,0,0,0,[]);
      }
      
      let status = "In Play"
      let color = "blue"
      let detailDate = dayjs().format('YYYY MMM DD')
      if (wager.event === null) {
        console.log(`wager  ${wager.text} has null event`);
      } else {
        detailDate = dayjs(wager.event.wagerCloseDate).format('YYYY MMM DD')
      }
    
      if(wager.arbitrated){
        stats.arbd += 1
        detailDate = dayjs(wager.arbitrationdate).format('YYYY MMM DD');
        let results=wager.arbitrationwinner=== myId?"won":"lost";
        results = wager.arbitrationwinner===undefined?"house":results;
        if (results==="lost"){
          stats.losses +=1
          color = "red"
        } else if (results==="won"){
          stats.wins +=1
          color = "green"
        }
        status = results.toUpperCase()
        stats.median = Math.round(100*stats.wins/stats.arbd).toFixed(1) - 50;
      } else {
        stats.current += 1;
      }

      stats.details.push({
        text: wager.text,
        date: detailDate,
        status: status,
        color: color,
        game: wager.game
      });
      newMap.set(myOpponent, stats);
    })
    setOpponentMap(newMap);

    // eslint-disable-next-line
  }, [wagers]);

  if (opponentMap.size === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No opponents found.</h2>
        </Card>
      </div>
    );
  }
  
  return (
    <Grid container  style={{ minWidth: 300 }}>
       <Grid item xs={12}>
            <Paper>
              

              <Grid container xs={12} sx={{textAlign:"left"}}>
                
                <Grid item xs={4}> </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Current</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Tot H2H</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Your Wins</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Paper>
        </Grid>
        <Divider width = '100%'></Divider>
        
      {[...opponentMap].map(([name, stats]) => {
        return (
          <React.Fragment>
            <Grid item xs={12}>
             <Accordion>
                <AccordionSummary
                  aria-controls="{name}-content"
                  id="{name}-header"
                >
                  <Grid item xs={4}>
                      <Typography color="secondary" fontSize={24}>
                        <Link  color="secondary" to={`/profile/${name}`} ><b>{name}{stats.suffix}</b></Link>
                      </Typography>
                  </Grid>
                  <Grid item xs={2}>
                      <Typography color="grey" fontSize={24}>{stats.current}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="grey" fontSize={24}>{stats.arbd}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="grey" fontSize={24}>{stats.wins}</Typography>  
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="Green" fontSize={24}>{stats.median>=0?"+":""}{stats.median}</Typography>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <div>
                      {stats.details.map(detail => 
                        <p>
                          <Grid container xs={12} sx={{textAlign:"left"}}>
                            <Grid item xs={6}>
                              <Typography color="black" fontSize={12}>
                                <Link to={`/viewgame/${detail.game}`}>{commentTruncator(45,detail.text)}</Link>
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography color="grey" fontSize={12}>{detail.date}</Typography>
                            </Grid>
                              <Grid item xs={2}>
                            <Typography color={detail.color} fontSize={12}>{detail.status}</Typography>
                            </Grid>
                          </Grid>
                        </p>
                      )}
                  </div>
                </AccordionDetails>
              </Accordion>
              </Grid>
          </React.Fragment>
        )
        })
      }
    </Grid>
  );
};

export default ProfileLeaderboard;
