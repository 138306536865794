import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';


import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { getTimezoneParameter } from '../../shared/helpers/utilities'
import { setEventCloseDate } from '../../shared/helpers/utilities';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { BetContext } from '../../shared/context/bet-context';
import AddWagerTitle from '../components/AddWagerTitle';
import AddWagerBodyEditable from '../components/AddWagerBodyEditable';
import AddWagerTeamSports from '../components/AddWagerTeamSports';
import AddWagerRaceRanking from '../components/AddWagerRaceRanking';

import './BasicForm.css';

//comes up when you create a wager and select an opponent
const Panel = (props) => (
  <div hidden={props.value !== props.index}>
    <Typography>{props.children}</Typography>
  </div>
);

const CreateBet = () => {
  const bet = useContext(BetContext);
  const auth = useContext(AuthContext);
  const { isLoading } = useHttpClient();
  const [closeDate, setCloseDate] = useState(bet.eventEndDate);
  const [disableEventChanges] = useState(bet.topicArea!=="ALL");
  const [invalidForm, setInvalidForm] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [wagerCloseDate, setWagerCloseDate] = useState(bet.wagerCloseDate);
  const navigate = useNavigate();
  

  const handleFormSubmit = (value) => {
    setInvalidForm(value);
  }

  const eventSubmitHandler = async event => {
    event.preventDefault();
    navigate(`/addbetpreview`);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const dateChangeHandler = (event) =>{
    setEventCloseDate(bet, event);
    setCloseDate(event);
    setWagerCloseDate(bet.wagerCloseDate);
  }
  const titleChangeHandler = (event) =>{
    bet.eventTitle = event.target.value;
  }

  const navigateOpponent = ()=>{
    navigate("/addbetuser");
   }


   const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const big = { fontSize: 25};

  return (
    <React.Fragment>
      {!isLoading  && (
        <Grid container style={{ maxWidth: 375 }} >

          <Grid item xs={8}>
            <Typography color="black" style={{  paddingTop: '10px' ,paddingLeft: '5px' }} >
              <b>CREATE WAGER</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link to="/"><Button variant="text" sx={{ float: 'left' , paddingTop: '10px' }}>Cancel</Button></Link>
          </Grid>
          
          <AddWagerTitle />

          <Typography Typography color = 'secondary' style={big}>                    
            <div style={{ paddingLeft: '10px'}}>Your Wager</div>                      
          </Typography>

          { bet.topicArea === "ALL" && (
            <Grid sx={{backgroundColor: 'grey.300', paddingBottom: '10px' , paddingTop: '10px',paddingRight: '10px'}}>
              <AddWagerBodyEditable inputHandler={handleFormSubmit}/>
            </Grid>    
          )}

          { bet.topicArea === "SPORTS" && !bet.rankingGame && (
            <Box sx={{ width: '100%', typography: 'body1' }}>              
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={tabChange}>
                    <Tab label="Points" /> 
                    <Tab label="Over/Under"  />
                    <Tab label="Free Form" />
                  </Tabs>
              </Box>
              
              <Panel value={tabValue} index={0}>
                <Typography Typography color = 'secondary' style={big}>                    
                <Grid sx={{backgroundColor: 'grey.300', paddingBottom: '10px' , paddingTop: '10px',paddingRight: '10px'}}>
                  <AddWagerTeamSports inputHandler={handleFormSubmit} form="points"/>
                </Grid>                     
                </Typography>
              </Panel>
              <Panel value={tabValue} index={1}>
                <Grid sx={{backgroundColor: 'grey.300', paddingBottom: '10px' , paddingTop: '10px',paddingRight: '10px'}}>
                  <AddWagerTeamSports inputHandler={handleFormSubmit} form="overunder"/>
                </Grid>
              </Panel>  
              <Panel value={tabValue} index={2}>
                <Grid sx={{backgroundColor: 'grey.300', paddingBottom: '10px' , paddingTop: '10px',paddingRight: '10px'}}>
                  <AddWagerBodyEditable inputHandler={handleFormSubmit}/>
                </Grid>
              </Panel>          
            </Box>           
          )}

          { bet.rankingGame && (
            <Box sx={{ width: '100%', typography: 'body1' }}>              
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={tabChange}>
                  <Tab label="Ranking" /> 
                  </Tabs>
              </Box>
              
              <Panel value={tabValue} index={0}>
                <Grid sx={{backgroundColor: 'grey.300', paddingBottom: '10px' , paddingTop: '10px',paddingRight: '10px'}}>
                  <AddWagerRaceRanking inputHandler={handleFormSubmit} />
                </Grid>
              </Panel>    
            </Box>           
          )}

          <Typography Typography color = 'secondary' style={big} >
            <div style={{float: 'left' , paddingLeft: '10px'}}>Wager Title</div>  
          </Typography>
          
          <TextField 
              sx={{backgroundColor: 'grey.300'}}
              style={{float: 'left'}}
              id="message"
              element="input"
              type="text"
              onChange={titleChangeHandler}
              name="message"
              placeholder=" title or event name here" 
              value={bet.eventTitle}
              variant="standard"
              fullWidth="true"
              disabled={disableEventChanges}
          />
        
          <Typography Typography color = 'secondary' style={big} >           
            <div style={{float: 'left' , paddingLeft: '10px'}}>Wager Ends?</div> 
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{backgroundColor: 'grey.300', paddingBottom: '10px' , paddingTop: '10px',paddingRight: '10px'}}
                  style={{paddingLeft: '10px'}}
                  value={closeDate}
                  onChange={dateChangeHandler}
                  disabled={disableEventChanges}
                />
              </LocalizationProvider>             
          </Typography>    
          
          
          <Typography Typography color='secondary' >
            <div style={{float: 'left'}}>Invite Acceptance Before:
              <br/><b>{`${dayjs(wagerCloseDate).utc().tz(getTimezoneParameter(auth.timezone)).format('MM/DD/YYYY hh:mm A')}`}</b>
            </div>  
          </Typography>

          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
            <div align="left">
                <Button variant="text" onClick={navigateOpponent}>Back</Button>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <div align="right">
                <Button variant="contained" onClick={eventSubmitHandler} disabled={invalidForm}>Review Wager</Button>
              </div>
            </Grid>
          </Grid>
            
      
     
        </Grid>
      )}
      
    </React.Fragment>
  );
};

export default CreateBet;
