import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab'; 
import AddIcon from '@mui/icons-material/Close';

const GameRules = (props) => {

    const splits = useParams().gamename.split("--");
    console.log(`splits: ${splits.length}-${splits[0]}-${splits[1]}`);
    const gameName = splits[0];
    let text = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."];
    if (splits[1] !== "undefined" ) {
      text =  splits[1].split("<br>");
    }
    //   
    const navigate = useNavigate();
    
    const goBack = () => {
      navigate(-1);
    }
  
    return (
      <React.Fragment>
        <h5 align ='center'>Rules for {gameName}</h5>
        <Grid container style={{ minWidth: 200, minHeight:300 }}>

            <Grid item xs={1}/>
            <Grid item xs={10}>
              <div>
                         {text.map((line, index) => (
                           <React.Fragment key={index}>
                            {console.log(`line: ${line}`)}
                           {line}
                           <br />
                           </React.Fragment>
                         ))}
                       </div>

            </Grid>

        </Grid>
        <Fab color="secondary" className="fab" size="large" aria-label="close" onClick={goBack}>
          <AddIcon />
        </Fab>
      </React.Fragment>
      )
    
}

export default GameRules;