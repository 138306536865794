import React , { useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { BetContext } from '../../shared/context/bet-context';
import { AuthContext } from '../../shared/context/auth-context';
let selectedDrivers = null;

const AddWagerRaceRanking = props => {
  const bet = useContext(BetContext);
  const auth = useContext(AuthContext);
  const numberOfEntries = bet.eventDetails.event.count;
  const driverIndexes = [...Array(numberOfEntries).keys()].map( i => (i + 1)+'.');
  const sortedParticipants = bet.eventDetails.event.participants.sort((a, b) => (a.firstName>b.firstName?1:-1));

  useEffect(() => {
    const fetchGames = () => {
      selectedDrivers = Array(numberOfEntries).fill("")
    };
    fetchGames();
  }, [numberOfEntries]);

  const inputHandler = (event) => {
    
    const values= event.target.value.split('.');
    const id = parseInt(values[0])-1;
    selectedDrivers[id]=values[1];

    let validInput =  new Set(selectedDrivers).size === selectedDrivers.length;
    if(validInput){
      validInput =selectedDrivers.filter(driver=> driver!=="").length === selectedDrivers.length;
    }
    if(validInput) {
      var text = `${auth.username} says the contest results will be:\n`;
      for (let i=0; i<selectedDrivers.length; i++){
        text +=  `${(i+1)}. ${selectedDrivers[i]} \n`
      }
      bet.betText = text;
      props.inputHandler(false);
    } else {
      props.inputHandler(true);
    }
    
  }

  return (

       <React.Fragment>
  
          <Grid container
            justify="center"
            alignItems="center"
          >
            <Grid  item xs={12}>             
              <Typography color="black" style={{  align: "left", fontWeight: 'bold', paddingRight:'10px' }}>
                <div align="center">              
                  {bet.eventTitle}
                </div>
              </Typography>               
            </Grid>   
            <Grid  item xs={12}>             
              <Typography color="red" style={{  align: "left", fontWeight: 'bold', paddingRight:'10px' }}>
                <div align="center">              
                  Select a unique {bet.eventDetails.event.label} for each final position
                </div>
              </Typography>               
            </Grid>  
            
            {driverIndexes.map(index =>(
              <React.Fragment>
              <Grid  item xs={2}>  
                <Typography color="black" style={{  align: "left", fontWeight: 'bold' }}>
                  <div align="center">              
                    {index}
                  </div>
                </Typography>   
              </Grid>
              <Grid  item xs={10}>                                 
                  <Select
                    id={index}
                    sx={{
                      width: 300
                    }}
                    onChange={inputHandler}
                  >
                    {sortedParticipants.map(participant =>(
                      <MenuItem value={index+participant.firstName+" "+participant.lastName}>{participant.firstName} {participant.lastName}</MenuItem>
                    ))}
                  </Select>             
              </Grid>
              </React.Fragment>
            ))}

          </Grid>

      </React.Fragment>

  );

  
  
};

export default AddWagerRaceRanking;
